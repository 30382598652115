/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const DHV = () => {
  return (
    <div>
      <p>The valve line offered by DHV and Himark is designed and manufactured to the highest standards of quality, reliability, and safety, providing customers with optimal solutions for controlling and regulating fluid flow in various industries.</p>
      <h1>Features:</h1>
      <ul>
        <li>
          Floating Ball Valves
          <ul>
            <li>1 Piece / 2 Piece / 3 Piece body designs</li>
            <li>Raised Face flanges / NPT threaded / Socket weld / Pup ends</li>
            <li>Full and reduced bore</li>
            <li> Cast and forge steel components</li>
            <li> Anti-blowout stem design (API 608)</li>
            <li>Anti-static device</li>
            <li>Low emissions</li>
          </ul>
        </li>
        <li>
          Trunnion Ball Valves
          <ul>
            <li>2 Piece and 3 piece bolted body type</li>
            <li> Full and reduced bore</li>
            <li> Ball valve Size: 2“ - 48”</li>
            <li> Ansi 150#-2500#</li>
            <li> API 6D, API 608</li>
            <li> Body: LF2(A350)/A105, WCB(A216), LCC(A352), 316SS</li>
            <li> Trim(Ball/Stem): LF2 w/3Mil ENC, 316SS</li>
            <li> Seats+: Devlon, Viton GLT, PEEK, RTFE (Glass Filled), RTFE (PEEK Filled), Metal Seat Trim available</li>
            <li> Bubble tight seat seals</li>
            <li> Double block and bleed</li>
            <li> Anti-blowout stem design (API 608)</li>
            <li> Anti-static device</li>
            <li> Double piston / self-relieving seat designs (DBB / DIB1 / DIB2)</li>
            <li> Emergency grease stem sealing</li>
            <li> Emergency grease seat to ball sealing</li>
          </ul>
        </li>
      </ul>
      <h1>Applications:</h1>
      <ul>
        <li>Upstream, Transmission pipelines, pumping, compression</li>
        <li>Re-injection units, terminals, pig traps, measuring stations, cryogenic-LNG</li>    
        <li>High Temperature & Abrasive Services</li> 
      </ul> 
      <h1>Product Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/">DHV Floating Vall Valve Product Page</a></li>  
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/valves-actuation/isolation-shutoff-valves/trunnion-ball-valves/dhv-trunnion-mounted-ball-valve/">DHV Trunnion Mounted Ball Valve Product Page</a></li>
      </ul> 
    </div>
  )
}
  
export default DHV

