/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const TankProtectionSolutionsTwo = () => {
  return (
    <div>
    <p>The Smart Enardo Series 850 and 950 pressure vacuum relief valves are versatile solutions for controlling pressure and vacuum in tanks and vessels. With options for wireless or wired position feedback and features such as leakage control, replaceable components, and overpressure detection, these valves ensure reliable and efficient opera􀆟on in various applications.</p>
      <h1>Features:</h1>
      <ul class="ul1">
	  <li>Model 850 - Pipe away</li>
    <li> Model 950 - Vent to atmosphere</li>
    <li>Sizes 2 Through 12</li>
    <li> Leakage not to exceed 0.1 SCFH at 90% of set point</li>
    <li> Enardo PVR's used in conjunction with VRU's and or Tank Blanketing Systems</li>
    <li> Fully Replaceable Pallet and Seat Assemblies</li>
    <li> Be notified of overpressure situations</li>
    <li> Determine PVRV sticking or failure</li>
    <li> Trend PVRV operation to ensure operability</li>
    <li> Topworx Go Switch Model #73-12364-A2</li>
    <li> Rosemount 702 Wireless Discrete Dual Input Transmitter</li>
    <li> Rosemount 3051 Pressure Transmitier</li>
</ul>
<h1>Option 1 - Wireless Position Feedback</h1>
<ul>
	<li>Rosemount 702 Wireless Transmitter
    <ul>
      <li>Topworx Go Switch 73</li>
      <li>Rosemount 3051 Pressure Transmitter with Flushing Ring</li>
    </ul>
  </li>
</ul>
<h1>Option 2 - Wired Position Feedback</h1>
<ul>
  <li>Topworx Go Switch 73</li>
</ul>
      <h1>Links</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noreferrer noopener" href="https://www.spartancontrols.com/products/valves-actuation/tank-vents-and-hatches/pressure-vacuum-relief-valves/enardo-series-850-high-performance-pipe-away-pressure-vacuum-relief-valves/">Tank Protection Solutions Smart Enardo Enhanced Sealing PV RV Product Page</a></li>
		<li><a className="text-blue-500" target='_blank' rel="noreferrer noopener" href="https://www3.emersonprocess.com/Regulators/Animation/PVRV%20850/Enardo%20PVRV%20850%20Animation.html">Enardo 850 Enhanced Sealing Pressure Vacuum Relief Valve Product Animation</a></li>
      </ul>
    </div>
  )
}
  
export default TankProtectionSolutionsTwo
