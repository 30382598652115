/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const Rosemount5300 = () => {
  return (
    <div>
      <p>The Rosemount 5300 Level Transmitter is ideal for challenging measurements on liquids, slurries, and solids, offering state of the art reliability and safety features in level and interface applications.</p>
      <h1>Features:</h1>
      <ul>
 	<li class="p1">Direct Switch Technology gives increased sensitivity, high reliability, and long measurement ranges</li>
 	<li class="p1">Signal Quality Metrics gives you the ability to work proactively with your level instrumentation</li>
 	<li class="p1">Probe End Projection offers greater level measurement reliability</li>
 	<li class="p1">Dynamic Vapor Compensation for improved plant heat rate</li>
 	<li class="p1">Verification reflector for remote proof-testing and unique level transmitter verification</li>
 	<li class="p1">Ultra-thin layer detection through Peak-in-Peak technology</li>
</ul>
      <h1>Specifications:</h1>
      <ul>
        <li>Accuracy: <span className="underline">+</span> 0.12 in. (3 mm)</li>
        <li>Repeatability: <span className="underline">+</span> 0.04 in. (1 mm)</li>    
        <li>Measurement Range: Up to 164 ft (50 m)</li>
        <li>Operating Pressure: Full vacuum to 5000 psi (Full vacuum to 345 bar)</li>   
        <li>Operating Temperature:-320 to 752 °F (-196 to 400 °C)</li>
        <li>Communication Protocol: 4-20 mA/HART™, Foundation™ Fieldbus, Modbust™</li>   
        <li>Safety: SIL 2 IEC 61508 certification</li>
        <li>TÜV tested and WHG approved for overfill prevention</li>   
        <li>Remote proof-test capabilities through verification reflector</li>
        <li>Diagnostics: Enhanced diagnostics enabling proactive maintenance</li>
        <li>Probe Types: Rigid single lead, Segmented single lead, Flexible single lead, Rigid twin lead, Flexible twin lead, Coaxial and large coaxial, PTFE coated probes, Vapor probe</li>   
        <li>Warranty: Up to five years</li>  
      </ul> 
      
      <h1>Product Links:</h1>
      <ul>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/measurement-instrumentation/level-measurement/continuous-level-measurement/rosemount-5300-level-transmitter-guided-wave-radar/" target="_blank" rel="noopener noreferrer">Rosemount 5300 Level Transmitter Product Page</a>
        </li>
      </ul>
    </div>
  )
}
  
export default Rosemount5300
