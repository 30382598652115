/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const EnardoES665 = () => {
  return (
    <div>
      <p>The Smart Enardo™ ES665 Thief Hatch is a spring-loaded hatch designed for secure closure, certified for hazardous locations, and compatible with wireless monitoring systems. It features smart wireless position feedback, open status notifications, and the ability to track and timestamp thief hatch open events, ensuring safety and efficient monitoring in industrial settings.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Spring-loaded mechanism for secure closure</li>
<li> Ultra-low leakage, 0.10 SCFH at 90% of set pressure at ambient conditions</li>
<li> Minimizes emissions and product losses</li>
<li> “Sour gas” models available</li>
<li> Wireless position feedback</li>
<li> Rosemount 702 Wireless Discrete Transmitier
	<ul>
		<li> Topworx Go Switch Model 73-12364-A2
			<ul>
				<li> SPDT contacts rated 4amp / 120 VAC, 3amp / 24 VDC</li>
				<li> CSA Cl 1, Div. 1, groups A-D certification for hazardous locations</li>
			</ul>
		</li>
		<li> Rosemount™ 702 Wireless Discrete Transmitier
			<ul>
				<li> Dual discreet , detects momentary inputs & counts</li>
				<li> CSA-Intrinsically safe certified transmitier</li>
			</ul>
		</li>
		<li> Rosemount™ Wireless gateway for wireless communication
			<ul>
				<li> CSA-Intrinsically safe certified transmitier</li>
			</ul>
		</li>
	</ul>
</li>
<li> Pressure/Temperature monitoring with Rosemount™ 2088/644 devices</li>
<li> Open and latch status notification</li>
<li> Smart wireless position feedback</li>
<li> Timestamping and tracking of open events</li>
</ul>
      <h1>Links:</h1>
      <ul>
	  <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/tank-vents-and-hatches/spring-loaded-hatches/enardo-model-es-665-and-es-665-l-spring-loaded-thief-hatch/" target="_blank" rel="noopener noreferrer">Smart Enardo ES-665 & ES-665-L Thief Hatch Product Page</a></li>
        <li><a className="text-blue-500" href="https://www3.emersonprocess.com/regulators/Animation/ES-665/ES665%20Product%20Animation.html" target="_blank" rel="noopener noreferrer">Smart Enardo ES-665 Thief Hatch Product Animation</a></li>
		
      </ul>
    </div>
  )
}
  
export default EnardoES665
