/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const Empowerment = () => {
  return (
    <div>
      <p>Securely connect to the cloud by installing the SCES-EDGE in the field, islands of data can be accessed and connected to Spartan Empower Services, where you can see the health of your assets and remotely assess and interpret data from your equipment.</p>
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">Modular architecture allows for easy addition of communication capabilities.</li>
 	<li className="li1">Capable of communicating to multiple devices via RS232/485 or WirelessHART, or Ethernet</li>
 	<li className="li1">Supports up to 10 simultaneous protocol conversions between 300+ industrial drivers.</li>
 	<li className="li1">Advanced web server delivers operations visibility anywhere.</li>
 	<li className="li1">Real-Time data logging to microSD card, API, or via ftp</li>
</ul>
      <h1>Applications:</h1>
      <ul className="ul1">
 	<li className="li1">The SCES-EDGE can work as a standalone unit connected directly to the device, as a communication gateway for existing SCADA systems, or as a combination of both.</li>
 	<li className="li1">Data collected locally by the gateway can be analyzed and filtered at the edge, so you only send what you need to the cloud.</li>
</ul>
      <h1>Links</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/getattachment/2cbf8b0c-386f-4907-bc66-bf55b562f9c9/spartan-controls-sces-edge_10-2023.pdf">Empower Brochure</a></li>
      </ul>
    </div>
  )
}
  
export default Empowerment
