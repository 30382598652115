/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const Torqplusfail = () => {
  return (
    <div>
      <h1>Features:</h1><ul>
<li> Economical</li>
<li> Robust, easy to service, conventional actuator</li>
<li> Compact design with mechanical fail-safe option - 115 VAC only</li>
<li> Mechanical fail-safe spring</li>
<li> Torques up to 1,600 inch-Ibs</li>
<li> 100% duty cycle</li>
</ul><h1>Modulation:</h1><ul>
<li> PeakTronics high resolution DHC400/100 digital positioner</li>
<li> Adapts to change in load and actuator conditions</li>
<li> Solid state motor drive with thermal/ overload protection and stall detection-100% Duty cycle</li>
<li> Optional OCM-102 Modbus communication</li>
</ul><h1>Applications:</h1><ul>
<li> Angle Body and In-Line Chokes</li>
<li> Qtr. Turn Valves: Ball, Dampeners, Butterfly Valves</li>
<li> ESDV-Qtr. Turn</li>
</ul></div>
  )
}
  
export default Torqplusfail
