/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const SmartTank = () => {
  return (
    <div>
      <p>Ensures precise control of gas pressure to prevent air ingress in storage tanks, optimizing safety and operational efficiency across various industries. It provides reliable performance and enhanced visibility in managing components, preventing contamination and supporting sustainability and compliance initiatives.</p>
      <h1>Features:</h1>
      <ul>
        <li>Ability to maintain low pressure setings and fast response times, ensuring efficient operation</li> 
        <li>They offer accurate control with minimal lock-up pressure, suitable for various applications requiring precision</li> 
        <li>Additionally, these regulators are compatable with sour gas services and are constructed to withstand low ambient temperatures, down to -50°C, enhancing their versatility and reliability in challenging environments</li> 
        <li>Trend pressure regulator operation to ensure operability</li> 
        <li>Be notified if the valve goes 100% open which could indicate loss of blanketing gas</li> 
        <li>Determine pressure regulator sicking or failure when used with a tank pressure transmitter or a smart wireless PVRV</li>    
      </ul> 
      <h1>Applications:</h1>
<ul>
  <li>Pressure control for gas storage: Ensuring precise regulation in storage tanks to optimize efficiency and minimize methane emissions</li>
  <li>Emissions reduction: Facilitating capture and control of methane emissions through accurate monitoring, supporting net zero goals</li>
  <li>Sour gas handling: Safely managing components to prevent contamination and ensure compliance, contributing to sustainability</li>
  <li>Cold climate operation: Withstanding low temperatures for reliable performance, crucial for net zero objectives</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/valves-actuation/regulators/tank-blanketing/" rel="noopener noreferrer">Smart Tank Blanketing VRR Product Page</a></li>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/Regulators/Type%201190%20Product%20Animation/Type%201190%20Product%20Animation.html" rel="noopener noreferrer">Fisher Type 1190 Regulator Product Animation</a></li>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/Regulators/Animation/T205/T205%20Product%20Animation.html" rel="noopener noreferrer">Fisher Type T205 Product Animation</a></li>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/Regulators/T205B%20Product%20Animation/T205B%20Product%20Animation.html" rel="noopener noreferrer">Fisher Type T205B Product Animation</a></li>
      </ul>
    </div>
  )
}
  
export default SmartTank
