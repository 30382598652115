/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const EnardoFlame = () => {
  return (
    <div>
      <p>Enardo™ Flame Arrestors offer reliable and effective protection against dangerous flame propagation in industrial processes, ensuring the safety of personnel and equipment while minimizing downtime and maximizing productivity.</p>
      <h1>Features:</h1>
      <ul>
<li> Designed for unstable detonation</li>
<li> Maximum flow</li>
<li> Bi-directional</li>
<li> Easily removable flame cell</li>
<li> Available with ANSI, DIN and JIS Flanges</li>
</ul>
<h1>Specifications:</h1>
<ul>
<li> Sizes - 1/2 through 36</li>
<li> Housing materials - carbon steel</li>
<li> Bi-directional</li>
<li> Cell materials - 304 SS</li>
<li> Gas groups - IIA (D)</li>
<li> Options - drain Plugs, pressure taps, misc. fittngs, protective coatings, special features</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/flame-and-detonation-arrestors/flame-arrestors/enardo-series-7-in-line-flame-arrestor/" target="_blank" rel="noopener noreferrer">
          Enadro Series 7 | Flame Arrestor Product Page 
          </a>
        </li>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/flame-and-detonation-arrestors/flame-arrestors/enardo-series-8-high-pressure-deflagration-arrestor/" target="_blank" rel="noopener noreferrer">
          Enadro Series 8 | Flame Arrestors Product Page 
          </a>
        </li>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/flame-and-detonation-arrestors/flame-arrestors/enardo-dfa-series-detonation-flame-arrestors/" target="_blank" rel="noopener noreferrer">
          Enardo DFA Series | Flame Arrestor Product Page 
          </a>
        </li>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/flame-and-detonation-arrestors/flame-arrestors/enardo-il-series-threaded-in-line-flame-arrestors/" target="_blank" rel="noopener noreferrer">
          Enadro IL Series l Flame Arrestor Product Page 
          </a>
        </li>
        <li>
          <a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/flame-and-detonation-arrestors/flame-arrestors/enardo-series-8800-and-9900-vent-stack-flame-arrestors/" target="_blank" rel="noopener noreferrer">
          Enadro Series 8800 & 9900 | Flame Arrestor Product Page
          </a>
        </li>
        <li>
          <a className="text-blue-500" href="https://www3.emersonprocess.com/Regulators/Animation/Flame%20Arrestor/Flame%20Arrestor%20Animation.html" target="_blank" rel="noopener noreferrer">
          Flame Arrestor Product Animation 
          </a>
        </li>
      </ul>
    </div>
  )
}
  
export default EnardoFlame
