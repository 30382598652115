/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const FisherET = () => {
  return (
    <div>
      <p>The Fisher™ easy-e™ ET Control Valve is a high-performance globe/angle valve designed for precision control in industrial applications. With a compact design, ease of maintenance, and a wide range of options, it offers reliable performance and efficient operation.</p>
      <h1>Features:</h1>
      <ul className="ul1">
    <li className="li1">Certifications: SIL capable, fugitive emission, NACE</li>
    <li className="li1">Critical service: noise abatement, cavitation, steam condition, erosive, low flow, corrosive, general service, outgassing, flashing</li>
    <li className="li1">Flow characteristics: equal percentage, linear, special characterization, quick opening</li>
    <li className="li1">Material: carbon steel, stainless, duplex, alloy</li>
    <li className="li1">Process connection type: flanged, butt weld, RTJ, threaded, socket weld</li>
    <li className="li1">Operating temperature: cryogenic, high temperature, standard temperature</li>
    <li className="li1">Shutoff class: class IV (FCI 70-2), class V (FCI 70-2), class VI (FCI 70-2)</li>
    <li className="li1">Valve size: NPS 1 through NPS 30</li>
    <li className="li1">ENVIRO-SEAL™ packing systems that provide an improved stem seal to help prevent the loss of process fluid are available: these packing systems feature PTFE or graphite ULF packing with live-loading for reduced packing maintenance.</li>
    <li className="li1">Increased wear resistance of hardened stainless-steel trim: longer-lasting service</li>
    <li className="li1">Unless otherwise noted: references are to NACE MR0175-2002. Optional materials are available to meet NACE MRO103 and NACE MR0175 / ISO 15156</li>
</ul>

      <h1>Applications:</h1>
      <ul className="ul1">
 	<li className="li1">Cavitation</li>
	<li>Inlet Control Valve</li>
 	<li className="li1">Corrosive</li>
 	<li className="li1">Dirty Service</li>
 	<li className="li1">Erosive</li>
 	<li className="li1">Flashing</li>
 	<li className="li1">General Service</li>
 	<li className="li1">Low Flow</li>
 	<li className="li1">Noise Abatement</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/control-valves/globe-angle-valves/fisher-easye-et-control-valve/" target="_blank" rel="noopener noreferrer">Fisher easy-e ET Control Valve Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default FisherET
