/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/

import React, { useEffect, useState } from 'react';

const MapLink = ({ onClick, title, buttonX, buttonY, line2XEnd, line2YEnd, line1Offset, circleOX, circleOY, startLabel }) => {
  // Initial state for line coordinates
  const [lineCoords, setLineCoords] = useState({ 
    buttonX     : buttonX, 
    buttonY     : buttonY, 
    line1Offset : line1Offset, 
    line2XEnd   : line2XEnd,
    line2YEnd   : line2YEnd,
    circleOX    : circleOX,
    circleOY    : circleOY,
    startLabel  : startLabel
  });

  useEffect(() => {
    const handleResize = () => {
      const theHeight = 844;
      const theWidth = 1500;
      const root = document.getElementById('main');
      
      let windowH = root.offsetHeight;
      let windowW = root.offsetWidth;
      let widthRatio = (theHeight * windowW) / theWidth;

      if ( widthRatio > windowH ) {
        windowW = (theWidth * windowH) / theHeight;
        widthRatio = windowH;
      }

      let markerTopRatio = widthRatio / theHeight;
		  let markerLeftRatio = windowW / theWidth;

      // Calculate and update line coordinates based on window size
      setLineCoords({ 
        buttonX      : lineCoords.buttonX * markerLeftRatio, 
        buttonY      : lineCoords.buttonY * markerTopRatio, 
        line1Offset  : lineCoords.line1Offset,
        line2XEnd    : lineCoords.line2XEnd * markerLeftRatio,
        line2YEnd    : lineCoords.line2YEnd * markerTopRatio,
        circleOX     : circleOX,
        circleOY     : circleOY,
        startLabel   : startLabel
      });
    };

    // Set the resize event listener
    window.addEventListener('resize', handleResize);

    // Initial resize to set line
    handleResize();

    // Cleanup the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div 
        onClick={onClick}
        className={`marker border border-white px-6 py-1 border-solid hover:bg-opacity-100 bg-darkblue bg-opacity-50 rounded-3xl text-white absolute xl:text-xl 2xl:text-2xl z-20 cursor-pointer`} 
        style={{
          top : lineCoords.buttonY + 'px', 
          left: lineCoords.buttonX + 'px',
        }} 
      >
        {title}
      </div>
      <svg className="marker-line z-10">
        <line 
          x1={lineCoords.buttonX} 
          y1={lineCoords.buttonY} 
          x2={lineCoords.buttonX - lineCoords.line1Offset} 
          y2={lineCoords.buttonY} 
          stroke="#fff"
          strokeWidth={2}
        />
        <line 
          x1={lineCoords.buttonX - lineCoords.line1Offset} 
          y1={lineCoords.buttonY} 
          x2={lineCoords.line2XEnd} 
          y2={lineCoords.line2YEnd} 
          stroke="#fff"
          strokeWidth={2}
        />
        <circle 
          cx={lineCoords.line2XEnd + lineCoords.circleOX}  
          cy={lineCoords.line2YEnd + lineCoords.circleOY} 
          r="12" 
          stroke="#fff" 
          fill="transparent" 
          strokeWidth={2} 
        />
      </svg>
      {startLabel && (
        <div 
          onClick={onClick} 
          className='border-r-2 border-l-2 border-t-2 border-solid border-white px-3 py-1 text-white absolute start-label cursor-pointer z-20'
          style={{
            top : (lineCoords.buttonY + 30) + 'px', 
            left: (lineCoords.buttonX - 104) + 'px',
          }}>
            Start
        </div>
      )}
    </>
  )
}

export default MapLink;