/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const FisherL2e = () => {
  return (
    <div>
      <p>The Fisher L2e electric on/off level controller for reliable liquid level detection. Designed with a displacer-type sensor, it's ideal for oil and gas separators, treaters, and scrubbers. Its force-balanced design ensures consistent performance in critical applications.</p>
      <h1>Features:</h1>
      <ul className="ul1">
    <li className="li1">Position control: on/off</li>
    <li className="li1">Process control: level</li>
    <li className="li1">Intuitive zero and span adjustments for flexible loop performance</li>
    <li className="li1">Premium hermetically sealed switch with gold contacts for accurate liquid level control</li>
    <li className="li1">No leakage current</li>
    <li className="li1">Controller performance maintains in high vibration installations</li>
    <li className="li1">Displacer adjustable for vertical or horizontal operation without extra parts</li>
    <li className="li1">Sensor easily disassembled for seal inspection or replacement</li>
    <li className="li1">Field-replaceable controller with no repairable parts</li>
</ul>

<h1>Specifications:</h1>
<ul className="ul1">
    <li className="li1">Area classNameification: explosion proof, flame proof, dust ignition proof</li>
    <li className="li1">Certifications: CSA, ATEX, IECEx, PED, CUTR</li>
    <li className="li1">Data interface: wired</li>
    <li className="li1">Diagnostics: no</li>
</ul> 

<h1>Applications:</h1>
<ul className="ul1">
    <li className="li1">Monitor liquid levels for separation of oil, gas, and water</li>
    <li className="li1">Regulate liquid levels to remove impurities from crude oil or natural gas</li>
    <li className="li1">Ensure precise level control in production processes</li>
</ul>

      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/controllers-and-instruments/level-sensors-and-controllers/fisher-l2e-electric-level-controller/" target="_blank" rel="noopener noreferrer">Fisher L2e Electric Level Level Controllers Product Page</a></li>
      </ul>
    </div>
  )
}

export default FisherL2e
