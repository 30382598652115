/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/

const BettisFailSafe = () => {
  return (
    <div>
      <p>The Bettis RTS Fail-Safe Linear Electric Actuator is a true mechanical fail-safe actuator utilizing spring technology to take a valve to a fail state position on loss of power or signal. The RTS Fail-Safe Linear Actuator is not reliant on battery-backup or super-capacitor technology. It provides both on/off and modulating control. It is used for any Linear valve applica-tions that required to either fail closed or open during an ESD event.</p>
      <h1>Features:</h1>
      <ul>
 	<li aria-level="1">No separate UPS, battery backup or supercapacitors required</li>
<li> Precise control over fluid and gas flow, reducing emissions and energy consumption</li>
<li>easy installation and maintenance, reducing downtime and costs</li>
<li>Robust construction for reliable operation in harsh industrial environments</li>
<li>Low power draw, .8mA idle / max 4.6 amp at full speed and torque/thrust</li>
<li>Advanced optimization & control</li>
<li>Precise process control - .1%</li>
<li> Advanced diagnostic capabilities for real-time feedback on valve performance</li>
<li>Advanced diagnostics and protocols-PID Control /HART /Modbus/Profibus</li>
</ul>
      <h1>Applications:</h1>
      <ul>
 	<li aria-level="1">Wellhead ESD choke valves - angle</li>
   <li>Plunger/gas Lift - angle body chokes or D bodies</li>
   <li>Separator inlet linear control valves - D-Bodies, HPT's</li>
   <li>Separator back pressure control valves - ET's</li>
</ul>
<h1>Product Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-fl-fail-safe-linear-electric-actuator/" target="_blank" rel="noopener noreferrer">Bettis RTS LF Actuator Product Page</a></li>
        <li><a className="text-blue-500" href="https://www.emerson.com/en-us/catalog/emerson-dcmlink-software" target="_blank" rel="noopener noreferrer">Asset monitoring - DCM Link Software</a></li>
      </ul>
    </div>
  )
}
  
export default BettisFailSafe
