/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const FB1200 = () => {
  return (
    <div>
      <p>FB1200 Flow Computer Multi-Run Explosion-Proof Flow Computer for Gas and Liquids. The FB1200 Flow Computer measures and controls gas and liquids flow for up to two differential pressure or linear meter runs. The rugged housing and multiple I/O, communications, and power options make the FB1200 model an ideal choice for accurate and reliable flow measurement in the harshest conditions. It offers ease of use and is highly configurable with support for multiple flow and fluid property calculations right out of the box.</p>
      <h1>Features:</h1>
      <ul>
 	<li aria-level="1">API 21.1 compliant measurement on up to two gas or liquid meter runs</li>
	 <li aria-level="1"> Industry leading differential and static pressure measurement including 5-year stability</li>
	 <li aria-level="1"> Superior data logging capacity with the ability to store 1000 alarms, 2000 events (separate legal events) and history</li>
<li aria-level="1"> High accuracy temperature measurement including curve matching via the Callendar-Van Dusen equation</li>
<li aria-level="1"> Reduced need to re-calibrate resulting in less time spent on site</li>
<li aria-level="1"> Low power requirements</li>
<li aria-level="1"> Simplified configuration and set-up with new FBxConnect™ tool; configuration comparison report allows for easier tracking of changes</li>
<li aria-level="1"> Standard firmware supports global metrology calculations (verified by NMi) for orifice, cone, Venturi, nozzle, conditioning orifice, turbine, PD, Auto-Adjust and Coriolis</li>
<li aria-level="1"> Global Hazardous Area Approvals - Class I Div 1 & 2, ATEX & IEC Ex d & Ex n</li>
<li aria-level="1"> FBxWifi (optional) allows secure, local wireless access from the safe area</li>
<li aria-level="1"> Ease of integration with support for Modbus, ROC, BSAP and DNP3 protocols</li>
</ul>
      <h1>Product Link:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/remote-automation-scada/flow-computers-rtus/flow-computers-rtus/fb1200-multi-run-explosion-proof-flow-computer-for-natural-gas/" target="_blank" rel="noopener noreferrer">FB1200 Flow Computer Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default FB1200
