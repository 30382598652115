/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const RoxarSand = () => {
  return (
    <div>
      <p>The Roxar™ SAM Acoustic Sand Monitor is a non-intrusive sensor that provides real-time production sand information, allowing increased oil and gas production rates and fewer topside erosion events.</p>
      <h1>Features:</h1>
      <ul>
        <li>Non-intrusive, easy to install</li>
        <li>Easy to understand sand intensity and quantification data</li>
        <li>Sand quantification when combined with sand injection calibration</li>
        <li>Can be combined with intrusive sand erosion and corrosion monitors and Fieldwatch for a complete integrity management system</li>
        <li>Can be combined with Roxar Multiphase Meters for 4 phase metering including sand</li>
      </ul>
      <h1>Specifications:</h1>
      <ul>
        <li>Uncertainty: +/- 5% (with sand injection calibration)</li>
        <li>Particle Detection: Typically, from 15 um (gas) and 25 um (liquid)</li>    
        <li>Flow Velocity: Minimum 1 m/s</li>
        <li>Pipe Surface Temperature: -40°C to 290°C (-40F to 554°F)</li> 
        <li>Size: 88 mm (OD) x 100 mm</li>
        <li>Weight: 3.0 Kg</li> 
        <li>Input Voltage: 100-240 V AC, 50/60 Hz / 24 VDC</li>
        <li>Communication: Modbus RTU (RS485), Analogue 4-20ma and Voltage Free Contact</li> 
      </ul> 
      <h1>Applications:</h1>
      <ul>
        <li>Sand erosion and corrosion monitoring</li>
        <li>Prevention of sand production and deposition</li>
        <li>Real-time sand production rate monitoring</li>
        <li>Minimizing topside erosion and equipment damage</li>
        <li>Enhancing well integrity and reliability</li>
        <li>Improving operational efficiency and reducing downtime</li>
        <li>Data-driven decision-making for sand control</li>
        <li>Improved environmental performance</li>
      </ul>
      <h1>Product Link:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/asset-management-reliability/integrity-condition-monitoring/corrosion-and-erosion/sand-detection-and-monitoring/roxar-sam-acoustic-sand-monitor/" target="_blank" rel="noopener noreferrer">Roxar SAM Acoustic Sand Monitoring Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default RoxarSand
