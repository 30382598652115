/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const DetTronicsFlexVU = () => {
  return (
    <div>
      <p>Standalone gas detection, control, and EQP integration<br />
The FlexVu® Universal Display is a rugged life-safety display that provides calibration, event logging and additional capabilities at the detector. Combining a FlexVu display with any of the compatible Det-Tronics sensors enables you to create a standalone detection and response device. A good addition to remote detectors, the FlexVu also reduces the cost of spares inventory because of its compatibility with a wide range of Det-Tronics gas detectors.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Backlit, heated LCD display</li>
 	<li class="li1">Detector compatibility:</li>
 	<li class="li1">Toxic gas: GT3000 Series, C706x Series, PIRECL-CO2</li>
 	<li class="li1">Combustible gas: PIR9400, PIRECL, LS2000, CGS Catalytic</li>
 	<li class="li1">FlexSonic® Acoustic Gas Leak Detector</li>
 	<li class="li1">Explosion-proof aluminum housing with a variety of connection configurations available.</li>
</ul>
      <h1>Technical:</h1>
      <ul>
 	<li class="p1">4-20 mA analog signal output with HART</li>
 	<li class="p1">Two (2) or three (3) programmable alarm relay outputs</li>
 	<li class="p1">One (1) fault relay output</li>
 	<li class="p1">RS-485 Modbus communications</li>
 	<li class="p1">4-20 mA analog signal input</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/fire-safety/fire-gas-detection/combustible-gas-detection/det-tronics-ud10/">Det-Tronics FlexVu Display Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default DetTronicsFlexVU
