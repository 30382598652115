/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const Boreal3 = () => {
  return (
    <div>
      <p>The Boreal Laser GasFinder3-DC is a cutting-edge gas detection system that utilizes advanced laser technology to accurately monitor and detect a wide range of gases in industrial environments. With its compact design and high sensitivity, it provides real-time data and enhances safety by enabling early detection of gas leaks and potential hazards.</p>
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">Remote Monitoring: Enables remote monitoring and detection of toxic and combustible gases across the well pad-wellhead, enhancing safety and enabling prompt response to potential hazards.</li>
 	<li className="li1">Large Area Coverage: Measures path lengths from 5-500 meters (15-1500 feet), providing comprehensive coverage of the monitored area.</li>
 	<li className="li1">Fast Speed and Results: Provides new and independent gas concentration results every second, ensuring real-time data for immediate action and decision-making.</li>
 	<li className="li1">Accuracy: Offers an accuracy level of +/- 2% of reading, ensuring reliable and precise gas concentration measurements.</li>
 	<li className="li1">Ambient Monitoring / Emissions Estimates: Allows for ambient monitoring and estimation of emissions, assisting in compliance with environmental regulations and monitoring of air quality.</li>
 	<li className="li1">Rugged and Robust Design: Operates in a wide temperature range of -55 to 650 degrees Celsius, ensuring durability and reliability in challenging environments.</li>
</ul>
<h1>Applications:</h1>
<ul className="ul1">
 	<li className="li1">Gas Leak Detection</li>
 	<li className="li1">Risk Mitigation</li>
   <li className="li1">Emissions Monitoring</li>
   <li className="li1">Environmental Monitoring</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/getattachment/21542f0c-bb29-49c1-ac52-319d2c3b3737/BorealLaser-GasFinder3-DC-Info-Package.pdf">Boreal GasFinder3 Product Brochure</a></li>
        <li><a className="text-blue-500" target='_blank' rel="noopener noreferrer" href="https://www.spartancontrols.com/products/fire-safety/fire-gas-detection/combustible-gas-detection/boreal-gasfinder3-mc/">Boreal GasFinder3 Product Page</a></li>
      </ul>
    </div>
  )
}

export default Boreal3
