/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const TankBlanketingSolutions = () => {
  return (
    <div>
      <p>The Fisher T205/205B, Y696, and 1190 Tank Regulators are ideal for tank blanketing applications. With their low-pressure seting, fast response, and accurate control, these regulators ensure precise blanketing of fluids in tanks. Additionally, their compatibility with sour gas service and low ambient temperature construction makes them suitable for handling corrosive gases and operating in extreme cold environments commonly encountered in tank blanketing processes.</p>
      <h1>Features:</h1>
      <ul class="ul1">
 	<li class="li1">Low pressure seting & fast speed of response for efficient control</li>
   <li class="li1">Accurate control with small lock-up pressure for precise regulation</li>
   <li class="li1">Sour gas service compatibility for handling corrosive gases</li>
   <li class="li1">Low ambient temperature construction up to -50°C for operation in extreme cold environments</li>
</ul>
      <h1>Specifications:</h1>
      <ul class="ul1">
 	<li class="li1">Body Size: NPS ¾" up to 12" x 6"</li>
   <li class="li1"> Control Pressure Range: 0.25 in. w.c. to 7 psig</li>
   <li class="li1">Maximum Inlet Pressure: 285 psig</li>
   <li class="li1"> Flow up to 2,811,000 SCH</li>
   <li class="li1">Regulator Styles: Direct-Operated, Balanced, Pilot Operated</li>
</ul>
<h1>Applications:</h1>
      <ul class="ul1">
 	<li class="li1">Pressure control in wellpad tanks for optimal operating conditions</li>
   <li class="li1">Precise regulation of gas flow in vapor recovery units (VRUs) to capture and recover emissions</li>
   <li class="li1">Blanketing of fluids in tanks for efficient and controlled processes</li>
   <li class="li1">Compatibility with sour gas service for handling corrosive gases</li>
   <li class="li1">Ability to operate in low ambient temperatures for extreme cold environments</li>
  </ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/Regulators/Type%201190%20Product%20Animation/Type%201190%20Product%20Animation.html" rel="noopener noreferrer">Fisher Type 1190 Regulator Product Animation</a></li>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/Regulators/Animation/T205/T205%20Product%20Animation.html" rel="noopener noreferrer">Fisher Type T205 Regulator Product Animation</a></li>
        <li><a className="text-blue-500" target='_blank' href="https://www3.emersonprocess.com/Regulators/T205B%20Product%20Animation/T205B%20Product%20Animation.html" rel="noopener noreferrer">Fisher Type T205B Regulator Product Animation</a></li>
      </ul>
    </div>
  )
}
  
export default TankBlanketingSolutions
