/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const FisherHPT = () => {
  return (
    <div>
      <p>The Fisher HP Series Control Valves, are high-performance globe and angle valves designed for precise control in demanding applications. With their advanced technology and rugged construction, these valves deliver reliable and accurate control of flow, pressure, and temperature in various industries.</p>
      <h1>Features:</h1>
      <ul className="ul1">
 	<li className="li1">Full Pressure Drop Capability: Rugged construction allows full pressure drop capability in HP series valves</li>
 	<li className="li1">Quick Change Trim: Maintenance is simple and can easily be performed using common tools. Trim components can be quickly removed and changed with no need for special tools</li>
 	<li className="li1">Standard Hard Trim Materials: The cage, valve plug, and other trim parts are manufactured from hardened materials. This standard feature provides excellent wear resistance</li>
 	<li className="li1">Valve Type: Angle, Globe</li>
 	<li className="li1">Material: Alloy, Carbon Steel, Cast Iron, Duplex</li>
 	<li className="li1">Application: Cavitation, Corrosive, Dirty Service, Erosive, Flashing, General Service, Low Flow, Noise Abatement</li>
 	<li className="li1">Certifications: ATEX, CRN, CSA, CUTR, FM, Fugitive Emission, IECE, NACE, PED, SIL capable</li>
 	<li className="li1">End Connection: Butt Weld, Flanged, RTJ, Socket Weld</li>
 	<li className="li1">Operating Temperature: Cryogenic, High Temperature, Standard Temperature</li>
 	<li className="li1">Flow Characteristics: Equal percentage, Linear, Special Characterization, Modified Equal Percentage</li>
</ul>
<h1>Applications:</h1>
<ul className="ul1">
 	<li className="li1">Cavitation</li>
	<li>Inlet Control Valve</li>
 	<li className="li1">Corrosive</li>
 	<li className="li1">Dirty Service</li>
 	<li className="li1">Erosive</li>
 	<li className="li1">Flashing</li>
 	<li className="li1">General Service</li>
 	<li className="li1">Low Flow</li>
 	<li className="li1">Noise Abatement</li>
</ul>
      <h1>Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/control-valves/globe-angle-valves/fisher-hp-series-control-valves/" target="_blank" rel="noopener noreferrer">Fisher HP Control Valve Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default FisherHPT
