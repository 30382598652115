/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const BettisRTS = () => {
  return (
    <div>
      <p>The Bettis RTS-CL Compact Linear Electric Actuator, is a reliable and precise electric actuator designed for linear valve control applications. With its compact design, high thrust output, and robust construction, it delivers accurate and consistent linear movement for efficient valve automation in various industrial settings.</p>
      <h1>Features:</h1>
<ul className="ul1">
    <li className="li1">Local user interface with wireless bluetooth control capability</li>
    <li className="li1">Highly configurable and intelligent actuator with options for continuous modulation (S9) and on/off (S2)</li>
    <li className="li1">Compact &amp; lightweight with corrosion protection</li>
    <li className="li1">Intelligent and non-intrusive</li>
    <li className="li1">Monitoring, and diagnostics</li>
    <li className="li1">Certified explosion and weatherproof</li>
    <li className="li1">Protocols: Modbus™, HART™, PROFIBUS™, FOUNDATION Fieldbus™, PROFINET™</li>
    <li className="li1">Bettis RTS CL compact linear electric valve actuator:
        <ul className="ul1">
            <li className="li1">Up to 25kN and 100mm stroke</li>
            <li className="li1">Adjustable speed (2sec - 54 sec)</li>
        </ul>
    </li>
    <li className="li1">Bettis RTS CM compact multi-turn electric valve actuator:
        <ul className="ul1">
            <li className="li1">Adjustable speed (2sec - 54 sec)</li>
            <li className="li1">Brushless DC motor technology</li>
            <li className="li1">Rotatable in 90 degree steps with remote mounting option</li>
            <li className="li1">User application available for Android devices</li>
        </ul>
    </li>
</ul>
<h1>Applications:</h1>
<ul className="ul1">
    <li className="li1">Wellhead choke valves: angle body and in-line</li>
    <li className="li1">Plunger / gas lift - angle body chokes or D bodies</li>
    <li className="li1">Separator inlet and back pressure control</li>
</ul>

      <h1>Product Links:</h1>
      <ul>
        <li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-cl-compact-linear-electric-actuator/" target="_blank" rel="noopener noreferrer">
		Bettis RTS CL Actuator Product Page
		</a></li>
		<li><a className="text-blue-500" href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-rts-cm-compact-multi-turn-electric-actuator/" target="_blank" rel="noopener noreferrer">Bettis RTS CM Valve Actuator Product Page</a></li>
      </ul>
    </div>
  )
}

export default BettisRTS
