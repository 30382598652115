/*!
* Copyright © Spartan Controls
* All Rights Reserved.
*/
const BettisTorqPlus = () => {
  return (
    <div>
      <h1>TorqPlus linear:</h1>
<ul>
    <li>Compact robust linear design</li>
    <li>Max stroke 1” up to 2-1/4”</li>
    <li>Max thrust 3800 lbs-ft</li>
    <li>Max speed 24 sec/1”</li>
</ul>

<h1>Applications:</h1>
<ul>
    <li>Choke valves - angle body</li>
    <li>Level control valves / dump valves<ul>
      <li>Fisher D2 / D3 / D4 - 1” to 4”</li>
      </ul></li>
    <li>Back pressure control valves<ul>
      <li>Fisher ET & HPT - 2” to 4”</li>
      </ul></li>
</ul>

<h1>TorqPlus multi-turn & quarter turn:</h1>
<ul>
    <li>Torque 325 in-lb to 1600 in-lb</li>
    <li>20 turn & 30 turn gear box for multi-turn</li>
    <li>Speed sec / 90° - 2 sec to 30 sec</li>
</ul>

<h1>Applications:</h1>
<ul>
    <li>Wellhead, sand separator and inlet choke valves</li>
</ul>

<h1>TorqPlus multi-turn & quarter turn:</h1>
<ul>
    <li>24 VDC & 115 VAC</li>
    <li>Modulating - high resolution digital positioner</li>
    <li>Solid state motor drive, 100% duty cycle</li>
    <li>Low power consumption</li>
    <li>Weatherproof & explosion proof</li>
</ul>

      <h1>Product Link:</h1>
      <ul>
        <li><a className="text-blue-500" target='_blank' href="https://www.spartancontrols.com/products/valves-actuation/actuators/electric-actuators/bettis-torqplus-electric-valve-actuator/" rel="noopener noreferrer">Bettis TorqPlus Linear Actuator Product Page</a></li>
      </ul>
    </div>
  )
}
  
export default BettisTorqPlus
